<template>
  <div class="comp_exp_sepelio conflex pt-3"
   if="schema.ctrls.id.value !== ''"
       style="justify-content:center;background-color: #eee;">

    <!-- <div
      class="row-principal comp_exp_llamada "
     
     > -->
    
      <div class="columna" style="width:550px">
        <div class="cab">INHUMACION / INCINERACION</div>

        <v-sheet  v-bind="$cfg.styles.marco">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.enterraen.value"
            :label="schema.ctrls.enterraen.label"
            :disabled="!is_edit">
          </v-text-field>

          <div style="display:flex">
            <v-text-field
              style="flex: 1 0 25%" 
              v-bind="$input"
              v-model="schema.ctrls.poliza_cpo.value"
              label="CP inhuma"
              :disabled="!is_edit">
            </v-text-field>

            <ctrlfinder
              style="flex: 1 0 75%" 
              finderName="atlas_F"
              :schema="schema.ctrls.enterraloc"
              :edicion="is_edit">
            </ctrlfinder>
          </div>

          <compfecha
            style="width:200px"
            :schema="schema.ctrls.enterrafhhr"
            :edicion="is_edit">
          </compfecha>
        </v-sheet>
               <div class="cab">TEXTOS CINTAS/RECORDATORIOS</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.recorda1.value"
            :label="schema.ctrls.recorda1.label"
            :disabled="!is_edit"
          ></v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.recorda2.value"
            :label="schema.ctrls.recorda2.label"
            :disabled="!is_edit"
          ></v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.recorda3.value"
            :label="schema.ctrls.recorda3.label"
            :disabled="!is_edit"
          ></v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.recorda4.value"
            :label="schema.ctrls.recorda4.label"
            :disabled="!is_edit"
          ></v-text-field>
        </v-sheet>
        <!-- ESQUELAS-->
        <div class="cab">ESQUELAS</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.sepesquela.value"
            :label="schema.ctrls.sepesquela.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.sepmodulo.value"
            :label="schema.ctrls.sepmodulo.label"
            :disabled="!is_edit"
          ></v-text-field>
        </v-sheet>

        <!-- VELATORIO -->
        <!-- <div class="sub-row"> -->

    

        <!-- </div> -->

        <!-- MISA -->
        <!-- <div class="sub-row"> -->

 
      </div>
      <!-- </div> -->

      <div class="columna">
        <!-- TRATAMIENTO -->
    <div class="cab">VELATORIO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.sepen.value"
            :label="schema.ctrls.sepen.label"
            :disabled="!is_edit">
          </v-text-field>

          <ctrlfinder
            finderName="atlas_F"
            :schema="schema.ctrls.seploc"
            :edicion="is_edit">
          </ctrlfinder>

          <compfecha
            style="width:200px"
            :schema="schema.ctrls.sepfhhr"
            :edicion="is_edit">
          </compfecha>
        </v-sheet>
        <div style="display:flex">
          <!-- UD. ENTERRAMIENTO -->
          <div class="columna">
            <div class="cab">ENTERRAMTO</div>
            <v-sheet width="225px" v-bind="$cfg.styles.marco">
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.sepnicho.value"
                :label="schema.ctrls.sepnicho.label"
                :disabled="!is_edit"
              ></v-checkbox>
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.sepsepultura.value"
                :label="schema.ctrls.sepsepultura.label"
                :disabled="!is_edit"
              ></v-checkbox>
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.sepcolumbario.value"
                :label="schema.ctrls.sepcolumbario.label"
                :disabled="!is_edit"
              ></v-checkbox>
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.sepcustodia.value"
                label="cenizas familia"
                :disabled="!is_edit"
              ></v-checkbox>
            </v-sheet>
          </div>
          <!-- <div class="columna"> -->
          <div class="columna">
            <div class="cab">TRATAMIENTOS</div>
            <v-sheet width="225px" v-bind="$cfg.styles.marco">
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.sepembalsa.value"
                :label="schema.ctrls.sepembalsa.label"
                :disabled="!is_edit"
              ></v-checkbox>
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.sepconserva.value"
                :label="schema.ctrls.sepconserva.label"
                :disabled="!is_edit"
              ></v-checkbox>
            </v-sheet>
          </div>
        </div>

        <!-- TEMPORALIDAD -->
        <!-- <div class="columna"> -->
        <div class="cab">TEMPORALIDAD</div>
        <v-sheet width="225px" v-bind="$cfg.styles.marco">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.seppropiedad.value"
            :label="schema.ctrls.seppropiedad.label"
            :disabled="!is_edit"
          ></v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.sepperpetuidad.value"
            :label="schema.ctrls.sepperpetuidad.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.sepalquiler.value"
            :label="schema.ctrls.sepalquiler.label"
            :disabled="!is_edit"
          ></v-checkbox>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.septemporal.value"
            :label="schema.ctrls.septemporal.label"
            :disabled="!is_edit"
          ></v-text-field>

          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.sepocupado.value"
            :label="schema.ctrls.sepocupado.label"
            :disabled="!is_edit"
          ></v-checkbox>
        </v-sheet>

        <!-- fin de display:row dentro de la columna -->

        <!-- CINTAS/RECORDATORIOS-->
 
      </div>

      <div class="columna" style="width:550px">
        <!-- OBS SEPELIO-->
               <div class="cab">MISA</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.misaen.value"
            :label="schema.ctrls.misaen.label"
            :disabled="!is_edit">
          </v-text-field>

          <ctrlfinder
            finderName="atlas_F"
            :schema="schema.ctrls.misaloc"
            :edicion="is_edit">
          </ctrlfinder>

          <compfecha
            style="width:200px"
            :schema="schema.ctrls.misafhhr"
            :edicion="is_edit">
          </compfecha>
        </v-sheet>
        <div class="cab">OBSERVACIONES SEPELIO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <ctrlobs3
            :schemaComponente="schema.ctrls.sepobs"
            :schema="schema"
            :record="record"
            :edicion="is_edit">
          </ctrlobs3>          
        </v-sheet>
        <!-- OBS CAMBIOS SERVICIO-->
        <div class="cab">MODIFICACION SERVICIO</div>
        <v-sheet v-bind="$cfg.styles.marco">
          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.obs_ser.value"            
            no-resize
            :disabled="!is_edit">            
          </v-textarea>         
        </v-sheet>

        <!-- OBS IAP-->
        <div class="cab">IAP OBERVACIONES</div>
        <v-sheet v-bind="$cfg.styles.marco" class="mb-5 pa-2" elevation="2">
          <v-textarea
            v-bind="$textarea"
            v-model="schema.ctrls.obs_iap.value"           
            no-resize
            :disabled="!is_edit">            
          </v-textarea>            
        </v-sheet>
      </div>
    </div>
  <!-- </div> -->
</template>



<script>

  import plugs from "@/common/general_plugs";
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");

  export default {
    components: { ctrlfinder, ctrlobs3, compfecha },
    props: {
      padre: { type:String, default: '' },      
      componenteTipo: { type:String, default:'M' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema:null,
      }
    },

    created() {
      this.ini_data();
    },

    methods:{
      ini_data() {
        console.log(" dev ********************** exp_M_incidencias ini_data");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.padre].schema;
      }
    },

    computed: {
      record: function() { return this.$store.state[this.padre].record; },
      
      // devuelvo si estamos en modo edición en el Mto
      is_edit() { 
        return this.$store.state[this.padre].estado === "editar" || this.$store.state[this.padre].estado === "nuevo"? true : false;
      },

      get_ctramit() {
        return this.$store.state.datos_iniciales.ct;
      },

      get_modalidad() {
        return this.$store.state.datos_iniciales.modalidades;
      },

      get_ramo() {
        return this.$store.state.datos_iniciales.ramos;
      },

      get_estadoCivil() {
        return [
          { id: "0", name: "Viudo/a" },
          { id: "1", name: "Divorciado/a" },
          { id: "2", name: "Casado/a" },
          { id: "3", name: "Soltero/a" },
          { id: "4", name: "" }
        ];
      }
    }
  };
</script>
